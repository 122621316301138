






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/app'
import firebase from 'firebase'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();

@Component({
  components: {
  },
})
export default class SignInView extends Vue {
  mounted() {
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          return true;
        },
      },
      signInFlow: 'redirect',
      signInSuccessUrl: '/manage',
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      tosUrl: '/terms',
      privacyPolicyUrl: '/privacy_policy'
    } as firebaseui.auth.Config;

    ui.start('#auth', uiConfig);

    firebase.auth().onAuthStateChanged(user => {
      AppModule.setCurrentUser(user);
      if (user) {
        db.collection('users').doc(user.uid).set({}, {merge: true});
        db.collection("hotels").doc(user.uid).set({user: db.collection('users').doc(user.uid)}, {merge: true});
      }
    });
  }
}
